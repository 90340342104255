import React, { useEffect, useContext } from 'react';
import { Match } from '@reach/router';
import NavLink from '../../components/helpers/NavLink';
import BudgetHUD from '../../components/global/BudgetHUD';
import { navigate } from '@reach/router';
import Item from '../../models/Item';
import ApplicationContext from '../../components/contexts/ApplicationContext';

export default function ItemLayout({ children }) {
  const context = useContext(ApplicationContext);

  useEffect(() => {
    const selectNameInput = (event) => {
      if (!event.target.closest('[gf-focus-item-name]')) {
        return;
      }

      const itemName = document.querySelector('#name');
      if (!itemName) {
        return;
      }

      itemName.focus();
    };
    document.addEventListener('click', selectNameInput);

    return () => {
      document.removeEventListener('click', selectNameInput);
    };
  });

  function deleteItemAndRedirect(itemId) {
    context.actions.destroyItem(itemId).then(() => {
      navigate('/items');
    });
  }

  return (
    <>
      <header className="bar bar-nav bar-primary">
        <Match path="/items">
          {(props) =>
            props.match && (
              <>
                <h1 className="title">Items</h1>
              </>
            )
          }
        </Match>
        <Match path="/items/new">
          {(props) =>
            props.match && (
              <>
                <NavLink className="btn btn-link pull-left" to="/items">
                  <span className="fa fa-chevron-left"></span> Back
                </NavLink>
                <h1 className="title">New Item</h1>
              </>
            )
          }
        </Match>
        <Match path="/items/edit/:id">
          {(props) =>
            props.match && (
              <>
                <NavLink className="btn btn-link pull-left" to="/items">
                  <span className="fa fa-chevron-left"></span> Cancel
                </NavLink>
                <h1 className="title">Edit</h1>
                <button
                  className="btn btn-negative pull-right"
                  onClick={() => deleteItemAndRedirect(props.match.id)}
                >
                  <span className="fa fa-trash-o"></span> Delete
                </button>
              </>
            )
          }
        </Match>
      </header>
      <BudgetHUD />
      <div className="success-check">
        <span className="fa fa-check-circle-o"></span>
      </div>
      <section className="content">
        {!context.state.loading && children}
      </section>
    </>
  );
}
