import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import ApplicationContext from '../../components/contexts/ApplicationContext';
import { navigate } from '@reach/router';

export default function ItemCreate() {
  const context = useContext(ApplicationContext);
  const { register, handleSubmit, watch, errors, reset, setValue } = useForm();
  const onSubmit = (formData) => {
    console.log(formData);

    formData.name = formData.nameSearch;
    delete formData.nameSearch;

    context.actions.createItem(formData).then(() => {
      if (formData.savePlus === 'false') {
        navigate('/items');
        return;
      }
      reset();
    });
  };

  return (
    <div className="panel">
      <h3>New Item</h3>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <input
          type="text"
          id="name"
          name="nameSearch"
          placeholder={errors.nameSearch ? 'Name is required' : 'Name'}
          ref={register({ required: true })}
        />
        <div className="input group">
          <span className="input-addon">
            <span className="fa fa-dollar"></span>
          </span>
          <input
            type="number"
            step="0.01"
            id="cost"
            name="cost"
            placeholder="Cost"
            ref={register}
          />
        </div>
        <label className="checkbox">
          <input type="checkbox" name="taxed" ref={register} /> Taxed
        </label>
        <label className="checkbox">
          <input
            type="checkbox"
            name="necessity"
            defaultChecked="checked"
            ref={register}
          />{' '}
          Necessity
        </label>
        <input type="hidden" name="savePlus" ref={register} />
        <div className="half-n-half">
          <button
            className="btn btn-outlined btn-positive btn-block"
            gf-focus-item-name="true"
            name="save"
            value="true"
            type="submit"
            onClick={() => {
              setValue('savePlus', false);
            }}
          >
            Save <span className="fa fa-shopping-basket"></span>
          </button>
          <button
            className="btn btn-positive btn-block"
            name="savePlus"
            value="true"
            type="submit"
            onClick={() => {
              setValue('savePlus', true);
            }}
          >
            Save <span className="fa fa-plus"></span>
          </button>
        </div>
      </form>
    </div>
  );
}
