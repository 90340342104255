import React, { useContext } from 'react';
import { Match } from '@reach/router';
import NavLink from '../../components/helpers/NavLink';
import ApplicationContext from '../../components/contexts/ApplicationContext';
import BudgetHUD from '../../components/global/BudgetHUD';

export default function BudgetLayout({ children }) {
  const context = useContext(ApplicationContext);

  return (
    <>
      <header className="bar bar-nav bar-primary">
        <Match path="/edit">
          {(props) =>
            props.match && (
              <>
                <NavLink className="btn btn-link pull-left" to="/">
                  <span className="fa fa-chevron-left"></span> Cancel
                </NavLink>
                <h1 className="title">Settings</h1>
              </>
            )
          }
        </Match>

        <Match path="/">
          {(props) =>
            props.match && (
              <>
                <h1 className="title">Budget</h1>
                <NavLink className="btn btn-link pull-right" to="/edit">
                  Edit
                </NavLink>
              </>
            )
          }
        </Match>
      </header>
      <Match path="/">{(props) => props.match && <BudgetHUD />}</Match>
      <div className="content">{!context.state.loading && children}</div>
    </>
  );
}
