import React, { useState, useEffect } from 'react';
import Budget from '../../models/Budget';
import TotalCost from '../../models/TotalCost';
import Item from '../../models/Item';
import TaxRate from '../../models/TaxRate';
import displaySuccess from '../../sketcy/displaySuccess';

const ApplicationContext = React.createContext();
export default ApplicationContext;

export const ApplicationProvider = ({ children }) => {
  const [loading, setLoadingState] = useState(true);
  const [budget, setBudget] = useState(0);
  const [taxRate, setTaxRate] = useState(4);
  const [totalCost, setTotalCost] = useState(0);
  const [available, setAvailable] = useState(0);
  const [budgetSafety, setBudgetSafety] = useState('empty');
  const [items, setItems] = useState([]);

  useEffect(() => {
    Budget.get()
      .then((dbBudget) => {
        console.log('dbBudget', dbBudget);
        setBudget(dbBudget.total);

        return TaxRate.get();
      })
      .then((dbTaxRate) => {
        setTaxRate(dbTaxRate.rate);

        return Item.all();
      })
      .then((dbItems) => {
        console.log('dbItems', dbItems);
        setItems(dbItems);
      })
      .finally(() => {
        setLoadingState(false);
      });
  }, []);

  useEffect(() => {
    TotalCost.update(taxRate).then((dbTotalCost) => {
      console.log('dbTotalCost', dbTotalCost);
      setTotalCost(dbTotalCost.total_cost);
    });
  }, [items, taxRate]);

  useEffect(() => {
    setAvailable(budget - totalCost);
  }, [totalCost, budget]);

  useEffect(() => {
    setBudgetSafety(determineBudgetSafety());
  }, [available, totalCost, budget]);

  function determineBudgetSafety() {
    // secure, warning, danger, insecure
    //-- secure if less than 1/2 of budget
    if (totalCost == 0) {
      return 'empty';
    }

    if (totalCost <= budget / 2) {
      console.log('current safety “secure”');
      return 'secure';
    }
    //-- warning if more than 1/2 but less than 3/4
    if (totalCost > budget / 2 && totalCost <= budget * 0.75) {
      console.log('current safety “warning”');
      return 'warning';
    }
    //-- danger if more than 3/4);
    if (totalCost > budget * 0.75 && totalCost <= budget) {
      console.log('current safety “danger”');
      return 'danger';
    }
    //-- insecure if budget is negative
    if (available < 0) {
      console.log('current safety “insecure”');
      return 'insecure';
    }
  }

  function updateBudget(formData) {
    console.log('you made it', formData);
    return Budget.update(formData).then(function(dbBudget) {
      setBudget(dbBudget.total);
    });
  }

  function updateTaxRate(formData) {
    console.log('you made it', formData);
    return TaxRate.update(formData).then(function(dbTaxRate) {
      setTaxRate(dbTaxRate.rate);
    });
  }

  function createItem(formData) {
    console.log('you made it', formData);
    return Item.store(formData).then(function(dbItems) {
      displaySuccess();
      setItems(dbItems);
    });
  }

  function updateItem(id, formData) {
    console.log('you made it', formData);
    return Item.update(id, formData).then(function(dbItems) {
      displaySuccess();
      setItems(dbItems);
    });
  }

  function destroyItem(itemId) {
    return Item.destroy(itemId).then(function(dbItems) {
      setItems(dbItems);
    });
  }

  const context = {
    state: {
      loading,
      budget,
      taxRate,
      items,
      totalCost,
      available,
      budgetSafety
    },
    actions: {
      updateBudget,
      updateTaxRate,
      createItem,
      updateItem,
      destroyItem
    }
  };

  return (
    <ApplicationContext.Provider value={context}>
      {children}
    </ApplicationContext.Provider>
  );
};

export const nullContext = {
  state: {
    items: []
  },
  actions: {}
};
