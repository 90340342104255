import React, { useContext } from 'react';
import ApplicationContext from '../../components/contexts/ApplicationContext';
import classnames from 'classnames';
import { Link } from '@reach/router';
import asCurrency from '../../viewHelpers/currency';
import capitalize from '../../viewHelpers/capitalize';

export default function ItemIndex() {
  const context = useContext(ApplicationContext);

  return (
    <ul className="table-view">
      <li className="table-view-divider">Items</li>
      {context.state.items.length === 0 && (
        <div className="content-padded">
          <h4>No Items yet</h4>
          <p>Tap the “Add Item” below to add your first Grocery Item.</p>
        </div>
      )}
      {context.state.items.map((item) => {
        return (
          <li
            className={classnames('table-view-cell', {
              muted: item.necessity
            })}
            key={item.id}
          >
            <Link className="navigate-right" to={`/items/edit/${item.id}`}>
              <span className="badge badge-primary" ng-if="item.cost">
                {asCurrency(item.cost)}
              </span>
              {capitalize(item.name)}
            </Link>
          </li>
        );
      })}
    </ul>
  );
}
