import React from 'react';
import { render } from 'react-dom';
import { Router } from '@reach/router';
import Navigation from './components/global/Navigation';

import BudgetLayout from './pages/budget/BudgetLayout';
import ItemLayout from './pages/items/ItemLayout';
import ItemCreate from './pages/items/ItemCreate';
import ItemIndex from './pages/items/ItemIndex';
import BudgetIndex from './pages/budget/BudgetIndex';
import BudgetEdit from './pages/budget/BudgetEdit';
import ItemEdit from './pages/items/ItemEdit';
import { ApplicationProvider } from './components/contexts/ApplicationContext';
import localforage from 'localforage';

const App = () => {
  localforage.config({
    name: 'groceryfriend',
    storeName: 'app' // Should be alphanumeric, with underscores.
  });

  return (
    <ApplicationProvider>
      <Router>
        <BudgetLayout path="/">
          <BudgetIndex path="/" />
          <BudgetEdit path="/edit" />
        </BudgetLayout>
        <ItemLayout path="/items">
          <ItemIndex path="/" />
          <ItemCreate path="new" />
          <ItemEdit path="edit/:id" />
        </ItemLayout>
      </Router>
      <Navigation />
    </ApplicationProvider>
  );
};

render(<App />, document.querySelector('[data-grocery-friend'));
