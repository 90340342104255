import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import ApplicationContext from '../../components/contexts/ApplicationContext';
import { navigate } from '@reach/router';

export default function BudgetEdit() {
  const context = useContext(ApplicationContext);
  const { register, handleSubmit, watch, errors } = useForm();
  const onSubmit = ({ total, rate }) => {
    context.actions.updateBudget({ total }).then(() => {
      context.actions.updateTaxRate({ rate }).then(() => {
        navigate('/');
      });
    });
  };

  return (
    <div className="panel">
      <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor="total">
          <h4>Edit Budget</h4>
        </label>
        <div className="input group">
          <span className="input-addon">
            <span className="fa fa-dollar"></span>
          </span>
          <input
            type="number"
            step="0.01"
            className="form-control input-lg"
            id="total"
            defaultValue={context.state.budget}
            name="total"
            ref={register({ required: true })}
          />
          {errors.total && <span>This field is required</span>}
        </div>

        <label htmlFor="total">
          <h4>Edit Tax Rate</h4>
          <p>
            <a
              href="https://www.avalara.com/taxrates/en/calculator.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Look up your local tax rate here.
            </a>
          </p>
        </label>
        <div className="input group">
          <input
            type="number"
            step="0.001"
            className="form-control input-lg"
            id="rate"
            defaultValue={context.state.taxRate}
            name="rate"
            ref={register({ required: true })}
          />
          <span className="input-addon">
            <span className="fa fa-percent"></span>
          </span>
          {errors.rate && <span>This field is required</span>}
        </div>

        <button type="submit" className="btn btn-block btn-positive">
          Update Settings
        </button>
      </form>
    </div>
  );
}
