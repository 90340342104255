import React, { useContext } from 'react';
import ApplicationContext from '../../components/contexts/ApplicationContext';
import asCurrency from '../../viewHelpers/currency';

export default function BudgetIndex() {
  const context = useContext(ApplicationContext);

  return (
    <div className="card">
      <h4>Budget</h4>
      <h1>{asCurrency(context.state.budget)}</h1>
      <p>&nbsp;</p>
      <h4>Tax Rate</h4>
      <h3>{context.state.taxRate.toFixed(3)}%</h3>
    </div>
  );
}
