import localforage from 'localforage';
import moment from 'moment';
import _ from 'lodash';

const Budget = {};
Budget.attributes = {};

Budget.get = function() {
  return localforage.getItem('budget').then(function(budget) {
    if (budget === null) {
      budget = {
        total: 0
      };
    }

    return budget;
  });
};

Budget.create = function(attributes) {
  if (attributes !== undefined) {
    Budget.attributes.created_at = moment().format('MM-DD-YYYY h:mm:ss a');
    Budget.attributes.total = Number(attributes.total);

    console.log('Budget.attributes', Budget.attributes);
  }
};

Budget.update = function(attributes) {
  if (attributes !== undefined) {
    Budget.create(attributes);
  }

  return Budget.get().then(function(budget) {
    console.log('budget recieved', budget);
    budget = _(budget)
      .merge(Budget.attributes)
      .value();
    console.log('budget calculated', budget);

    return localforage.setItem('budget', budget);
  });
};

Budget.store = Budget.update;

Budget.delete = function(index) {
  return localforage.setBudget('budget', {});
};

export default Budget;
