import localforage from 'localforage';
import createUUID from '../components/helpers/createUUID';

const Item = {};
Item.attributes = {};
var pocket = {};

Item.all = function() {
  return localforage.getItem('items').then((items) => {
    if (items === null) {
      items = [];
    }

    return items;
  });
};

Item.create = function(attributes) {
  if (attributes !== undefined) {
    if (attributes.name === undefined) {
      throw 'Name is required.';
    }

    Item.attributes.id = createUUID();
    Item.attributes.name = attributes.name;

    if (!attributes.cost) attributes.cost = 0;
    Item.attributes.cost = Number(attributes.cost);

    if (attributes.taxed === undefined) attributes.taxed = false;
    Item.attributes.taxed = attributes.taxed;

    if (attributes.necessity === undefined) attributes.necessity = false;
    Item.attributes.necessity = attributes.necessity;
  }
};

Item.store = function(attributes) {
  if (attributes !== undefined) {
    Item.create(attributes);
  }

  return pocket.getItems().then(function(items) {
    if (items === null) {
      items = [];
    }

    items.push(Item.attributes);

    return localforage.setItem('items', items);
  });
};

Item.update = function(id, attributes) {
  Item.create(attributes);

  return pocket.getItems().then(function(items) {
    const item = items.find((item) => item.id === id);
    const index = items.indexOf(item);
    items[index] = Item.attributes;

    return localforage.setItem('items', items);
  });
};

Item.destroy = function(id) {
  return pocket.getItems().then(function(items) {
    const currentItem = items.find((item) => item.id === id);
    const index = items.indexOf(currentItem);
    items.splice(index, 1);

    return localforage.setItem('items', items);
  });
};

pocket.getItems = function() {
  return localforage.getItem('items');
};

export default Item;
