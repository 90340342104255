import localforage from 'localforage';
import moment from 'moment';
import _ from 'lodash';

const TaxRate = {};
TaxRate.attributes = {};

TaxRate.get = function() {
  return localforage.getItem('taxRate').then(function(taxRate) {
    if (taxRate === null) {
      taxRate = {
        rate: 4
      };
    }

    return taxRate;
  });
};

TaxRate.create = function(attributes) {
  if (attributes !== undefined) {
    TaxRate.attributes.created_at = moment().format('MM-DD-YYYY h:mm:ss a');
    TaxRate.attributes.rate = Number(attributes.rate);

    console.log('TaxRate.attributes', TaxRate.attributes);
  }
};

TaxRate.update = function(attributes) {
  if (attributes !== undefined) {
    TaxRate.create(attributes);
  }

  return TaxRate.get().then(function(taxRate) {
    console.log('taxRate recieved', taxRate);
    taxRate = _(taxRate)
      .merge(TaxRate.attributes)
      .value();
    console.log('taxRate calculated', taxRate);

    return localforage.setItem('taxRate', taxRate);
  });
};

TaxRate.store = TaxRate.update;

export default TaxRate;
