import React from 'react';
import NavLink from '../helpers/NavLink';

export default function Navigation() {
  return (
    <nav className="bar bar-tab">
      <NavLink className="tab-item" to="/">
        <span className="icon icon-home"></span>
        <span className="tab-label">Budget</span>
      </NavLink>
      <NavLink className="tab-item" to="/items">
        <span className="icon icon-list"></span>
        <span className="tab-label">Items</span>
      </NavLink>
      <NavLink className="tab-item" gf-focus-item-name="true" to="/items/new">
        <span className="icon icon-plus"></span>
        <span className="tab-label">Add Item</span>
      </NavLink>
    </nav>
  );
}
