import React, { useContext } from 'react';
import classnames from 'classnames';
import asCurrency from '../../viewHelpers/currency';
import ApplicationContext from '../contexts/ApplicationContext';

export default function BudgetHUD() {
  const context = useContext(ApplicationContext);
  console.log('budgetSafety', context.state.budgetSafety);

  return (
    <>
      {!context.state.loading && (
        <div
          className={classnames(
            'bar bar-standard bar-header-secondary',
            'budget-hud',
            {
              empty: context.state.budgetSafety == 'empty',
              secure: context.state.budgetSafety == 'secure',
              warning: context.state.budgetSafety == 'warning',
              danger: context.state.budgetSafety == 'danger',
              insecure: context.state.budgetSafety == 'insecure'
            }
          )}
        >
          <div className="total-panel">
            <div className="total-cost">
              Total Cost <h4>{asCurrency(context.state.totalCost)}</h4>
            </div>
            <div className="available">
              Available <h4>{asCurrency(context.state.available)}</h4>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
