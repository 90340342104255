import React, { useContext, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import ApplicationContext from '../../components/contexts/ApplicationContext';
import { navigate } from '@reach/router';

export default function ItemEdit({ id }) {
  const context = useContext(ApplicationContext);
  const { register, handleSubmit, watch, errors, reset } = useForm();
  const [loading, setLoadingState] = useState(true);
  const [item, setItem] = useState({});
  const onSubmit = (formData) => {
    console.log(formData);

    formData.name = formData.nameSearch;
    delete formData.nameSearch;

    context.actions.updateItem(id, formData).then(() => {
      navigate('/items');
    });
  };

  useEffect(() => {
    const dbItem = context.state.items.find((item) => item.id === id);
    setItem(dbItem);
    console.log('item found', dbItem);
    setLoadingState(false);
  }, []);

  return (
    <div className="panel">
      {!loading && (
        <form
          ng-submit="edit.update()"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <input
            type="text"
            className="form-control"
            id="name"
            name="nameSearch"
            placeholder={errors.nameSearch ? 'Name is required' : 'Name'}
            defaultValue={item.name}
            ref={register({ required: true })}
          />
          <div className="input group">
            <span className="input-addon">
              <span className="fa fa-dollar"></span>
            </span>
            <input
              type="number"
              step="0.01"
              className="form-control"
              id="cost"
              name="cost"
              placeholder="Cost"
              defaultValue={item.cost}
              ref={register}
            />
          </div>
          <label className="checkbox">
            <input
              type="checkbox"
              name="taxed"
              defaultChecked={item.taxed}
              ref={register}
            />{' '}
            Taxed
          </label>
          <label className="checkbox">
            <input
              type="checkbox"
              name="necessity"
              defaultChecked={item.necessity}
              ref={register}
            />{' '}
            Necessity
          </label>
          <button type="submit" className="btn btn-positive btn-block">
            Save <span className="fa fa-shopping-basket"></span>
          </button>
        </form>
      )}
    </div>
  );
}
