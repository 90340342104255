import localforage from 'localforage';
import _ from 'lodash';

let TotalCost = {
  taxRate: 4
};
let pocket = {};

TotalCost.update = function(taxRate) {
  TotalCost.taxRate = taxRate;
  return pocket.getItems();
};

pocket.getTaxRate = () => {
  return TotalCost.taxRate / 100;
};

pocket.determineTotalCost = function(items) {
  console.log('items', items);
  TotalCost.total_cost = _.sum(
    _(items)
      .map(function(item) {
        if (item.cost === null || isNaN(item.cost)) {
          return 0;
        }

        if (item.taxed) {
          const tax = item.cost * pocket.getTaxRate();
          return item.cost + tax;
        }

        return item.cost;
      })
      .value()
  );

  if (isNaN(TotalCost.total_cost) || TotalCost.total_cost === '') {
    TotalCost.total_cost = 0;
  }

  TotalCost.total_cost = TotalCost.total_cost.toFixed(2);

  console.log(TotalCost);
  return TotalCost;
};

pocket.getItems = function() {
  return localforage.getItem('items').then(function(items) {
    if (items === null) {
      TotalCost.total_cost = 0.0;
      return TotalCost;
    }

    return pocket.determineTotalCost(items);
  });
};

export default TotalCost;
